import styled, { css } from 'styled-components';

export const columnStyles = (
  justify = 'flex-start',
  align = 'flex-start'
) => css`
  display: flex;
  flex-flow: column;
  justify-content: ${justify};
  align-items: ${align};
`;

export const rowStyles = (justify = 'flex-start', align = 'flex') => css`
  display: flex;
  flex-flow: row;
  justify-content: ${justify};
  align-items: ${align};
`;

type FlexProps = {
  flow: 'column' | 'row';
  justifyContent?: string;
  alignItems?: string;
  wrap?: string;
};

const toFlexStyles = ({ flow, justifyContent, alignItems }: FlexProps) => {
  switch (flow) {
    case 'column':
      return columnStyles(justifyContent, alignItems);
    case 'row':
      return rowStyles(justifyContent, alignItems);
  }
};

export const Flex = styled.div<FlexProps>`
  ${props => toFlexStyles(props)}
  flex-wrap: ${props => props.wrap ?? 'nowrap'};
`;
