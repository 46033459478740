import { useEffect, useState } from 'react';

function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    window?.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    setScrollTop(window.scrollY);
  };

  return { scrollTop };
}

export default useScrollTop;
